<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-code-integration"
  >
    <div class="card m-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_code_integration"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.TITLE") }}
          </span>
        </div>
      </div>

      <div
        id="collapse_code_integration"
        class="collapse"
        data-parent="#accordion-form-code-integration"
      >
        <div class="card-body">
          <b-alert show dismissible>{{
            $t("ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MESSAGE", {
              domainName: domainName
            })
          }}</b-alert>

          <div
            v-if="hasCodeIntegrations"
            class="d-flex flex-column align-items-center"
          >
            <v-btn
              fab
              small
              color="green"
              dark
              @click="addFirstCodeIntegrations"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <span class="ml-1">{{
              $tc(
                "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.LABEL_ADD_CODE"
              )
            }}</span>
          </div>

          <b-modal
            id="modal-1"
            :title="
              $tc(
                'ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_RULES.TITLE'
              )
            "
            ref="modal"
            hide-footer
            hide-header-close
          >
            <p class="my-4">
              {{
                $tc(
                  "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_RULES.FIRST_PART_RULES"
                )
              }}
            </p>
            <p>
              {{
                $tc(
                  "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_RULES.SECOND_PART_RULES"
                )
              }}
            </p>

            <div
              v-if="hasAccess('activatedCustomContent', 'read')"
              class="d-flex flex-column align-items-center text-center"
            >
              <b-form-checkbox
                v-model="activatedCustomContent"
                id="checkbox-consent"
                :state="checkAccept"
                title="checkbox-consent"
                :disabled="!hasAccess('activatedCustomContent', 'write')"
              >
                {{
                  $tc(
                    "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_RULES.LABEL_AGREE_RULES"
                  )
                }}
              </b-form-checkbox>
            </div>

            <div class="d-flex justify-content-center mt-3">
              <button
                class="btn btn-outline-primary mr-3"
                :disabled="isLoadingAcceptions"
                @click="$bvModal.hide('modal-1')"
              >
                <template v-if="!isLoadingAcceptions">
                  {{
                    $tc(
                      "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_RULES.CANCEL_RULES"
                    )
                  }}
                </template>

                <template v-if="isLoadingAcceptions">
                  <b-spinner label="Spinning"></b-spinner>
                </template>
              </button>
              <button
                class="btn btn-primary"
                block
                @click="submit"
                :disabled="isLoadingAcceptions"
                style="width: 80px;"
              >
                <template v-if="!isLoadingAcceptions">
                  {{
                    $tc(
                      "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_RULES.SUBMIT_RULES"
                    )
                  }}
                </template>

                <template v-if="isLoadingAcceptions">
                  <b-spinner label="Spinning"></b-spinner>
                </template>
              </button>
            </div>
          </b-modal>

          <div v-for="(item, index) in customTemplate" :key="`code_${index}`">
            <div class="d-flex flex-column mb-3">
              <div class="d-flex flex-row justify-content-end">
                <v-btn
                  icon
                  v-b-modal.modal-delete
                  @click="indexToDelete = index"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>

            <div
              v-if="hasAccess('customTemplate', 'read')"
              class="d-flex flex-row"
            >
              <div class="mb-3 w-25 mr-3">
                <span
                  v-if="!item.titleEditable"
                  class="title-code-integration pa-2 w-100"
                  @click="changeTitleEditable(index)"
                  @input="changetitle($event, index)"
                  >{{ item.title }}</span
                >

                <b-form-input
                  v-if="item.titleEditable"
                  v-model="item.title"
                  :id="`input-title-${index}`"
                  :state="checkTitle(item.title)"
                  class="form-control--grey pa-2 w-100"
                  :disabled="!hasAccess('customTemplate', 'write')"
                />

                <b-form-invalid-feedback :id="`input-title-${index}`">
                  {{
                    $tc(
                      "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.ERRORS_TITLE"
                    )
                  }}
                </b-form-invalid-feedback>

                <b-form-checkbox
                  v-model="item.activate"
                  title="check-button"
                  switch
                  class="mr-3 mt-2"
                >
                  {{
                    $tc(
                      "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.LABEL_ACTIVATE"
                    )
                  }}
                </b-form-checkbox>
              </div>

              <div class="d-flex flex-column w-100">
                <b-form-select
                  v-model="item.labelType"
                  :options="listScript"
                  :id="`input-type-${index}`"
                  :state="checkType(item.labelType)"
                  class="form-control form-control--grey"
                  @change="changeType($event, index)"
                  :disabled="!hasAccess('customTemplate', 'write')"
                ></b-form-select>

                <b-form-invalid-feedback :id="`input-type-${index}`">
                  {{
                    $tc(
                      "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.ERROR_TYPE"
                    )
                  }}
                </b-form-invalid-feedback>

                <div class="mt-3">
                  <div v-if="item.labelType.includes('external')">
                    <b-form-input
                      v-model="item.src"
                      :id="`input-src-${index}`"
                      :state="checkSrc(item.src)"
                      placeholder="Copy and paste your URL"
                      class="form-control--grey mt-3"
                    />

                    <b-form-invalid-feedback :id="`input-code-${index}`">
                      {{
                        $tc(
                          "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.ERROR_SRC"
                        )
                      }}
                    </b-form-invalid-feedback>
                  </div>

                  <b-form-textarea
                    v-else
                    v-model="item.script"
                    :id="`input-code-${index}`"
                    :state="checkScript(item.script)"
                    placeholder="Copy and paste your code here"
                    class="form-control--grey"
                    rows="3"
                  />

                  <b-form-invalid-feedback :id="`input-code-${index}`">
                    {{
                      $tc(
                        "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.ERROR_SCRIPT"
                      )
                    }}
                  </b-form-invalid-feedback>
                </div>
              </div>
            </div>
            <hr />
          </div>

          <b-modal
            id="modal-delete"
            :title="
              $tc(
                'ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_DELETE.TITLE'
              )
            "
            hide-footer
            hide-header-close
          >
            <p class="my-4">
              {{
                $tc(
                  "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_DELETE.LABEL_DELETE"
                )
              }}
            </p>

            <div class="d-flex flex-row justify-content-center">
              <button
                class="btn btn-outline-primary"
                @click="$bvModal.hide('modal-delete')"
              >
                {{
                  $tc(
                    "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_DELETE.CANCEL"
                  )
                }}
              </button>

              <button
                class="btn btn-primary ml-3"
                @click="deleteCodeIntegration()"
              >
                {{
                  $tc(
                    "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_DELETE.SUBMIT"
                  )
                }}
              </button>
            </div>
          </b-modal>

          <div
            v-if="!hasCodeIntegrations"
            class="d-flex flex-row align-items-center"
          >
            <v-btn fab small color="green" dark @click="addCodeIntegration">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <span class="ml-1">Add code to your domain</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listScript,
  elementCustomTemplate,
  elementHeaderHTML
} from "@/components/settings/formsDomain/domain.config.js";
import { isURL } from "@/common/functions";
import { getAttributesRead, hasAttributeAccess } from "@/common/config/acl";
import { FORM_CODE_INTEGRATION } from "@/common/config/acl/domain/codeIntegration";
import auth from "@/store/auth.module";

export default {
  props: {
    formProps: {
      type: Object,
      required: false
    },
    domainId: {
      type: String,
      required: false
    },
    isCanCheck: {
      type: Boolean,
      required: false
    },
    domainName: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      user: auth.state.user,
      form: getAttributesRead(FORM_CODE_INTEGRATION, auth.state.user.roles),
      customTemplate: [],
      activatedCustomContent: false,
      isLoadingAcceptions: false,
      isCanCheckCheckbox: false,
      listScript: [...listScript],
      indexToDelete: null
    };
  },

  mounted() {
    if (this.domainId) {
      this.activatedCustomContent = this.formProps.activatedCustomContent;

      if (this.formProps.headScripts) {
        this.formProps.headScripts.forEach(element => {
          if (!element.title) {
            element.title = `Code ${this.customTemplate.length + 1}`;
            element.labelType = [];
            element.labelType[element.labelType.length] = element.body
              ? "body"
              : "header";
            element.labelType[element.labelType.length] =
              element.src?.length > 0 ? "external" : "inline";
            element.labelType[element.labelType.length] = element.defer
              ? "defer"
              : "";
            element.labelType = element.labelType.join(" ");
            element.activate = true;

            const option = listScript
              .flatMap(script => script.options)
              .find(option => option.value.includes(element.labelType));

            element.labelType = option.value;
            element.type = option.data.type;
            element.attributes = { ...option.attributes };
          }

          element.titleEditable = false;

          this.customTemplate.push(element);
        });
      }
      elementCustomTemplate.forEach(element => {
        if (
          this.formProps.additionalHTML &&
          this.formProps.additionalHTML[element] &&
          typeof this.formProps.additionalHTML[element] === "object"
        ) {
          this.formProps.additionalHTML[element].titleEditable = false;
          this.customTemplate.push(this.formProps.additionalHTML[element]);
        } else if (
          this.formProps.additionalHTML &&
          this.formProps.additionalHTML[element]
        ) {
          this.customTemplate.push({
            title: `Code ${this.customTemplate.length + 1}`,
            src: "",
            script: this.formProps.additionalHTML[element],
            activate: true,
            labelType: element,
            type: element,
            titleEditable: false
          });
        }
      });

      elementHeaderHTML.forEach(element => {
        if (
          this.formProps[element] &&
          typeof this.formProps[element] !== "object"
        ) {
          this.customTemplate.push({
            title: `Code ${this.customTemplate.length + 1}`,
            src: "",
            script: this.formProps[element],
            activate: true,
            labelType: element,
            type: element,
            titleEditable: false
          });
        }
      });

      if (this.customTemplate.length > 0) {
        this.changeDisabledStateListScript();
      }
    }
  },

  computed: {
    hasCodeIntegrations() {
      return this.customTemplate.length === 0;
    },

    checkAccept() {
      return this.isCanCheckCheckbox ? this.activatedCustomContent : null;
    },

    hasErrorsCheckbox() {
      return !this.checkAccept;
    }
  },

  methods: {
    hasAccess(field, accessType) {
      return hasAttributeAccess(
        FORM_CODE_INTEGRATION,
        this.user.roles,
        field,
        accessType
      );
    },
    addCodeIntegration() {
      this.customTemplate.push({
        title: `Code ${this.customTemplate.length + 1}`,
        src: "",
        script: "",
        activate: true,
        labelType: "select",
        type: "",
        titleEditable: false
      });
    },

    changeTitleEditable(index) {
      const findIndex = this.customTemplate.findIndex(
        element => element.titleEditable === true
      );
      if (findIndex > -1) {
        this.customTemplate[findIndex].titleEditable = false;
      }
      this.customTemplate[index].titleEditable = true;

      setTimeout(() => {
        document.getElementById(`input-title-${index}`).focus();
      }, 20);
    },

    changetitle(e, index) {
      this.customTemplate[index].title = e.target.innerText;
    },

    deleteCodeIntegration() {
      this.customTemplate.splice(this.indexToDelete, 1);
      this.indexToDelete = null;
      this.changeDisabledStateListScript();
      this.$bvModal.hide("modal-delete");
    },

    async submit() {
      this.isCanCheckCheckbox = true;
      this.isLoadingAcceptions = true;

      setTimeout(async () => {
        if (!this.hasErrorsCheckbox) {
          try {
            this.$bvModal.hide("modal-1");
            this.addCodeIntegration();
            this.isCanCheckCheckbox = false;
            this.isLoadingAcceptions = false;
          } catch (error) {
            console.error("---ERROR-CODE-INTEGRATIONS---");
            console.error(error);
            this.isCanCheckCheckbox = false;
            this.isLoadingAcceptions = false;
          }
        } else {
          this.isLoadingAcceptions = false;
        }
      }, 2);
    },

    changeDisabledStateListScript() {
      this.listScript.forEach(element => {
        element.options.forEach(el => {
          const index = this.customTemplate.findIndex(
            e => el.value === e.labelType
          );
          if (el.unique && index === -1) {
            el.disabled = false;
          } else if (el.unique && index >= 0) {
            el.disabled = true;
          }
        });
      });
    },

    changeType(value, index) {
      this.changeDisabledStateListScript();

      this.listScript.forEach(element => {
        const findIndex = element.options.findIndex(el => value === el.value);
        if (findIndex > -1) {
          this.$set(this.customTemplate, index, {
            ...this.customTemplate[index],
            ...element.options[findIndex].data,
            attributes: { ...element.options[findIndex].attributes },
            type: element.options[findIndex].data.type,
            labelType: element.options[findIndex].value,
            title: this.customTemplate[index].title
          });
        }
      });
    },

    checkTitle(title) {
      return this.isCanCheck ? title.length > 3 : null;
    },

    checkType(type) {
      return this.isCanCheck ? type !== "select" : null;
    },

    checkScript(script) {
      return this.isCanCheck ? script.length > 0 : null;
    },

    checkSrc(src) {
      return this.isCanCheck ? !!isURL(src) : null;
    },

    checkSrcOrScript(element) {
      if (element.labelType.includes("external")) {
        return this.checkSrc(element.src);
      } else {
        return this.checkScript(element.script);
      }
    },

    hasErrors() {
      const hasErrors = this.customTemplate.filter(element => {
        if (
          this.hasAccess("customTemplate", "write") &&
          (!this.checkTitle(element.title) ||
            !this.checkType(element.labelType) ||
            !this.checkSrcOrScript(element))
        ) {
          return element;
        }
      });

      return hasErrors.length > 0;
    },

    addFirstCodeIntegrations() {
      if (this.activatedCustomContent) {
        this.addCodeIntegration();
      } else {
        this.$bvModal.show("modal-1");
      }
    },

    resetData() {
      this.customTemplate = [];
      this.listScript = [...listScript];
      this.indexToDelete = null;
    }
  }
};
</script>

<style scoped>
.title-code-integration {
  display: block;
  min-height: 38.39px;
  font-size: 13px;
  cursor: pointer;
}
.title-code-integration:hover {
  background: #ebecf0;
}
</style>
