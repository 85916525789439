export const menu = [
  {
    keyLabel: "ADVANCED_SETTINGS.MENU.DOMAINS",
    icon: "fas fa-list",
    active: false,
    disabled: true
  },
  {
    keyLabel: "ADVANCED_SETTINGS.MENU.PODCASTS",
    icon: "fas fa-podcast",
    active: true,
    disabled: false
  },
  {
    keyLabel: "ADVANCED_SETTINGS.MENU.PLAYLISTS",
    icon: "fas fa-play",
    active: false,
    disabled: true
  },
  {
    keyLabel: "ADVANCED_SETTINGS.MENU.USERS",
    icon: "fas fa-users",
    active: false,
    disabled: true
  },
  {
    keyLabel: "ADVANCED_SETTINGS.MENU.INVOICES_INFORMATIONS",
    icon: "fas fa-file-invoice",
    active: false,
    disabled: true
  }
];

export const defaultSellers = { "# Voxeus": [] };

export const defaultRobotsGoogleVerification = { company: null, owner: null };

export const defaultCPMConf = {
  name: "quantcast",
  id: "Lxr8PSb9GCw7A",
  domain: null,
  analytics: {
    google: {
      id: null,
      purposes: [1, 3, 7, 8, 9, 10],
      vendors: [755] // Google Advertising
    },
    atinternet: {
      id: null,
      purposes: [],
      vendors: []
    }
  }
};
