import { ROLES } from "@/common/config/acl/roles";
import { stringifyJson } from "@/assets/js/helper";
import { defaultSellers } from "@/components/settings/settings.config";

export const FORM_ADVERTISEMENT = {
  authorizedAds: {
    defaultValue: false,
    read: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER],
    write: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
  },
  authorizedAudioAds: {
    defaultValue: false,
    read: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER],
    write: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
  },
  audioAdsBackfilled: {
    defaultValue: false,
    read: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER],
    write: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
  },
  sellers: {
    defaultValue: stringifyJson(defaultSellers),
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN]
  },
  publisherAudioAdsCpm: {
    defaultValue: 0.0,
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN]
  },
  publisherAudioAdsFillRate: {
    defaultValue: 0.0,
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN]
  }
};
