import { ROLES } from "@/common/config/acl/roles";
import { defaultCPMConf } from "@/components/settings/settings.config";
import { stringifyJson } from "@/assets/js/helper";

export const FORM_GPDR = {
  cmp: {
    defaultValue: stringifyJson(defaultCPMConf),
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  }
};
