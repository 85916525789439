const defaultEndpointTemplate =
  "__DISCOVERY_ENDPOINT__discover/__PUBLISHER_COMPANY_ID__.js";
const varsScript =
  'window._vxs_discovery = window._vxs_discovery || { debug: __IS_DEBUG_MODE__ , slot: "__PUBLISHER_SLOT_LIST__", domain: "__HOSTNAME__" };';
const headerScript = `<script type="text/javascript" async src="${defaultEndpointTemplate}"></script>`;
const executeScript = `${varsScript} (function(d, a){ var s = d.createElement(a), x = d.getElementsByTagName(a)[0]; s.async = true; s.src = "${defaultEndpointTemplate}"; x.parentNode.insertBefore(s, x); })(document, "script");`;

export const executionScriptGenerator = ({
  slots,
  debugMode,
  companyId,
  endpoint,
  hostname
}) => {
  let script = executeScript.replace(
    /__IS_DEBUG_MODE__/,
    debugMode ? "true" : "false"
  );
  script = script.replace(/__PUBLISHER_SLOT_LIST__/, slots);
  script = script.replace(/__DISCOVERY_ENDPOINT__/, endpoint);
  script = script.replace(/__PUBLISHER_COMPANY_ID__/, companyId);
  script = script.replace(/__HOSTNAME__/, hostname);
  return script;
};

export const headerScriptGenerator = ({ companyId, endpoint }) => {
  let script = headerScript.replace(/__DISCOVERY_ENDPOINT__/, endpoint);
  script = script.replace(/__PUBLISHER_COMPANY_ID__/, companyId);
  return script;
};

export const varsScriptGenerator = ({ slots, debugMode }) => {
  let script = varsScript.replace(
    /__IS_DEBUG_MODE__/,
    debugMode ? "true" : "false"
  );
  script = script.replace(/__PUBLISHER_SLOT_LIST__/, slots);
  return script;
};
