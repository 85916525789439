export const listScript = [
  {
    label: "Select a script type",
    value: "select",
    options: [
      {
        text: "Select a script type",
        value: "select"
      }
    ],
    disabled: false
  },
  {
    label: "Body",
    options: [
      {
        text: "Inline",
        defer: false,
        value: "body inline",
        disabled: false,
        unique: false,
        attributes: {
          body: true
        },
        data: {
          name: "body inline",
          type: "inline"
        }
      },
      {
        text: "External",
        value: "body external",
        disabled: false,
        unique: false,
        attributes: {
          body: true
        },
        data: {
          name: "body external",
          type: "external"
        }
      },
      {
        text: "External async",
        value: "body external async",
        disabled: false,
        unique: false,
        attributes: {
          body: true,
          defer: true
        },
        data: {
          name: "body external async",
          type: "external"
        }
      },
      {
        text: "External defer",
        value: "body external defer",
        disabled: false,
        unique: false,
        attributes: {
          body: true,
          async: true
        },
        data: {
          name: "body external defer",
          type: "external"
        }
      }
    ]
  },
  {
    label: "Header",
    options: [
      {
        text: "Inline",
        value: "header inline",
        disabled: false,
        unique: false,
        attributes: {},
        data: {
          name: "header inline",
          type: "inline"
        }
      },
      {
        text: "External",
        value: "header external",
        disabled: false,
        unique: false,
        attributes: {},
        data: {
          name: "header external",
          type: "external"
        }
      },
      {
        text: "External async",
        value: "header external async",
        disabled: false,
        unique: false,
        attributes: {
          async: true
        },
        data: {
          name: "header external async",
          type: "external"
        }
      },
      {
        text: "External defer",
        value: "header external defer",
        disabled: false,
        unique: false,
        attributes: {
          defer: true
        },
        data: {
          name: "header external defer",
          type: "external"
        }
      }
    ]
  },
  {
    label: "Others",
    options: [
      {
        text: "header HTML",
        value: "headerHTML",
        disabled: false,
        unique: true,
        data: {
          name: "header HTML",
          type: "headerHTML"
        }
      },
      {
        text: "Global HTML",
        value: "global",
        disabled: false,
        unique: true,
        data: {
          name: "Global HTML",
          type: "global"
        }
      },
      {
        text: "Homepage HTML",
        value: "homepage",
        disabled: false,
        unique: true,
        data: {
          name: "Homepage HTML",
          type: "homepage"
        }
      },
      {
        text: "Program HTML",
        value: "program",
        disabled: false,
        unique: true,
        data: {
          name: "Program HTML",
          type: "program"
        }
      },
      {
        text: "Episode HTML",
        value: "episode",
        disabled: false,
        unique: true,
        data: {
          name: "Episode HTML",
          type: "episode"
        }
      }
    ]
  }
];

export const elementCustomTemplate = [
  "global",
  "homepage",
  "program",
  "episode"
];

export const elementHeaderHTML = ["headerHTML"];
