import { ROLES } from "@/common/config/acl/roles";

export const FORM_LINKS = {
  homeWebsiteUrl: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  urlTerms: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  urlPrivacy: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  urlLegals: {
    defaultValue: null,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  urlAbout: {
    defaultValue: null,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  }
};
