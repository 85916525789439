<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-gdpr"
  >
    <div class="card m-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_gdpr"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_GDPR.TITLE") }}
          </span>
        </div>
      </div>

      <div
        id="collapse_gdpr"
        class="collapse"
        data-parent="#accordion-form-gdpr"
      >
        <div class="card-body">
          <div
            v-if="hasAccess('cmp', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 w-25">{{
              $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_GDPR.LABEL_CMP")
            }}</label>

            <div class="d-flex flex-column w-75">
              <b-form-textarea
                v-model="form.cmp"
                :placeholder="
                  $tc('ADVANCED_SETTINGS.DOMAIN.CONFIG_GDPR.LABEL_CMP')
                "
                rows="8"
                class="form-control--grey"
                :disabled="!hasAccess('cmp', 'write')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/store/auth.module";
import { getAttributesRead, hasAttributeAccess } from "@/common/config/acl";
import { FORM_GPDR } from "@/common/config/acl/domain/gpdr";
import { stringifyJson } from "@/assets/js/helper";

export default {
  props: {
    domainId: {
      type: String,
      required: false
    },
    formProps: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      user: auth.state.user,
      form: getAttributesRead(FORM_GPDR, auth.state.user.roles)
    };
  },

  mounted() {
    if (this.domainId) {
      this.form.cmp = stringifyJson(this.formProps.cmp);
    }
  },

  methods: {
    hasAccess(field, accessType) {
      return hasAttributeAccess(FORM_GPDR, this.user.roles, field, accessType);
    },
    resetData() {
      this.form = getAttributesRead(FORM_GPDR, this.user.roles);
    }
  }
};
</script>
