<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-sections"
  >
    <div class="card m-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_sections"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.TITLE") }}
          </span>
        </div>
      </div>

      <div
        id="collapse_sections"
        class="collapse"
        data-parent="#accordion-form-sections"
      >
        <div class="card-body">
          <b-alert show dismissible class="d-flex flex-column">
            {{
              $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.ALERT.LABEL")
            }}</b-alert
          >

          <span
            >{{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.LABEL_CREATE") }}
            <strong
              >{{ getNumberSections }} / {{ maxSectionsCreate }}</strong
            ></span
          >

          <draggable
            v-model="sections"
            :animation="200"
            ghost-class="ghost-card"
          >
            <div
              v-for="(item, index) in sections"
              :key="`code_${index}`"
              class="mt-3"
            >
              <div class="d-flex flex-row">
                <div class="w-25 mr-3 d-flex align-items-center">
                  <span class="pa-2 w-100">{{
                    item.isDefault ? `Default` : `Sections #${index + 1}`
                  }}</span>

                  <b-form-invalid-feedback
                    :id="`input-title-sections-${index}`"
                  >
                    {{
                      $tc(
                        "ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.ERRORS_TITLE"
                      )
                    }}
                  </b-form-invalid-feedback>
                </div>

                <div class="d-flex flex-column w-100">
                  <b-input-group>
                    <template #append>
                      <b-input-group-text>
                        <img
                          src="@/assets/media/icons/svg/Design/Horizontal.svg?inline"
                          alt="Icon drag and drop card"
                          style="cursor: pointer; height: 20px;"
                        />
                      </b-input-group-text>

                      <v-btn
                        icon
                        color="error"
                        :disabled="item.isDefault"
                        v-b-modal.modal-delete-selections
                        @click="indexToDelete = index"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>

                    <b-form-input
                      v-model="item.name"
                      :id="`input-sections-${index}`"
                      :state="checkSections(item.name)"
                      :maxlength="maxLengthName"
                      :placeholder="
                        $tc(
                          'ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.PLACEHOLDER'
                        )
                      "
                      class="form-control--grey"
                    />
                  </b-input-group>

                  <span
                    v-if="checkSections(item.name) === false"
                    class="text-danger"
                  >
                    {{
                      $tc(
                        "ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.ERRORS_SECTIONS"
                      )
                    }}
                  </span>
                  <span class="text-right text-small text-muted">{{
                    `${item.name.length} / ${maxLengthName}`
                  }}</span>
                </div>
              </div>
              <hr />
            </div>
          </draggable>

          <b-modal
            id="modal-delete-selections"
            :title="
              $tc('ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.MODAL_DELETE.TITLE')
            "
            hide-footer
            hide-header-close
          >
            <p class="my-4">
              {{
                $tc(
                  "ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.MODAL_DELETE.LABEL_DELETE"
                )
              }}
            </p>

            <div class="d-flex flex-row justify-content-center">
              <button
                class="btn btn-outline-primary"
                @click="$bvModal.hide('modal-delete-selections')"
              >
                {{
                  $tc(
                    "ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.MODAL_DELETE.CANCEL"
                  )
                }}
              </button>

              <button
                class="btn btn-primary ml-3"
                @click="deleteCssSelectors()"
              >
                {{
                  $tc(
                    "ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.MODAL_DELETE.SUBMIT"
                  )
                }}
              </button>
            </div>
          </b-modal>

          <div
            v-if="hasSections && canCreateSections"
            class="d-flex flex-row align-items-center"
          >
            <v-btn fab small color="green" dark @click="addSections">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <span class="ml-1">{{
              $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.LABEL_CREATE")
            }}</span>
          </div>

          <div
            v-if="!hasSections && canCreateSections"
            class="d-flex flex-column align-items-center"
          >
            <v-btn fab small color="green" dark @click="addSections">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            {{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_SECTIONS.LABEL_CREATE") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

const MAX_SECTIONS_CREATE = 8;
const MAX_LENGTH_NAME = 60;

export default {
  components: {
    draggable
  },

  props: {
    form: {
      type: Array,
      required: true
    },
    isCanCheck: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      sections: [],
      indexToDelete: 0,
      maxSectionsCreate: MAX_SECTIONS_CREATE,
      maxLengthName: MAX_LENGTH_NAME
    };
  },

  mounted() {
    this.sections = [...this.form];
  },

  computed: {
    hasSections() {
      return this.getNumberSections > 0;
    },

    getNumberSections() {
      return this.sections.length;
    },

    canCreateSections() {
      return this.getNumberSections < MAX_SECTIONS_CREATE;
    }
  },

  methods: {
    addSections() {
      this.sections.push({
        name: "",
        indexOrder: this.getNumberSections.length - 1
      });
    },

    checkSections(sections) {
      return this.isCanCheck ? (sections.length > 0 ? true : false) : null;
    },

    deleteCssSelectors() {
      this.sections.splice(this.indexToDelete, 1);
      this.indexToDelete = null;
      this.$bvModal.hide("modal-delete-selections");
    },

    hasErrors() {
      const hasErrors = this.sections.filter(element => {
        if (!this.checkSections(element.name)) {
          return element;
        }
      });

      return hasErrors.length > 0;
    },

    resetData() {
      this.sections = [];
    }
  }
};
</script>
