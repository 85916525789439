<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-discovry-settings"
  >
    <div class="card m-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_discovry_settings"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DISCOVERY.TITLE") }}
          </span>
        </div>
      </div>

      <div
        id="collapse_discovry_settings"
        class="collapse"
        data-parent="#accordion-form-discovry-settings"
      >
        <div class="card-body">
          <b-alert show dismissible class="d-flex flex-column">
            <strong>{{
              $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DISCOVERY.ALERT.TITLE")
            }}</strong>
            <span class="mt-2">{{
              $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DISCOVERY.ALERT.PARAGRAPH")
            }}</span>

            <span class="mt-2"
              ><u>{{
                $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DISCOVERY.ALERT.FOOTER")
              }}</u>
            </span>
          </b-alert>

          <div v-if="hasCssSelectors">
            <label>{{
              $tc(
                "ADVANCED_SETTINGS.DOMAIN.CONFIG_DISCOVERY.LABEL_COPY_CPLIPBOARD"
              )
            }}</label>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  title="Copy"
                  @click.stop.prevent="copyToClipboard()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-clipboard-text</v-icon>
                </v-btn>
              </template>
              <span>{{
                $tc(
                  "ADVANCED_SETTINGS.DOMAIN.CONFIG_DISCOVERY.TOOLTIPS.LABEL_COPY"
                )
              }}</span>
            </v-tooltip>
          </div>

          <div
            v-if="!hasCssSelectors"
            class="d-flex flex-column align-items-center"
          >
            <v-btn fab small color="green" dark @click="addFirstCssSelectors">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            {{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DISCOVERY.LABEL_ADD_CSS") }}
          </div>

          <div
            v-for="(item, index) in form.discovery.cssSelector"
            :key="`code_${index}`"
            class="mt-3"
          >
            <div v-if="hasAccess('discovery', 'read')" class="d-flex flex-row">
              <div class="w-25 mr-3">
                <span
                  v-if="!item.titleEditable"
                  class="title-code-integration pa-2 w-100"
                  @click="changeTitleEditable(index)"
                  @input="changetitle($event, index)"
                  >{{ item.title }}</span
                >

                <b-form-input
                  v-if="item.titleEditable"
                  v-model="item.title"
                  :id="`input-title-css-${index}`"
                  :state="checkTitle(item.title)"
                  class="form-control--grey pa-2 w-100"
                  :disabled="!hasAccess('discovery', 'write')"
                />

                <b-form-invalid-feedback :id="`input-title-css-${index}`">
                  {{
                    $tc(
                      "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.ERRORS_TITLE"
                    )
                  }}
                </b-form-invalid-feedback>
              </div>

              <div class="d-flex flex-column w-100">
                <b-form-input
                  v-model="item.css"
                  :id="`input-css-${index}`"
                  :state="checkCss(item.css)"
                  placeholder="Put your CSS here"
                  class="form-control--grey"
                />

                <b-form-invalid-feedback :id="`input-css-${index}`">
                  {{
                    $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DISCOVERY.ERROR_CSS")
                  }}
                </b-form-invalid-feedback>
              </div>

              <v-btn
                icon
                color="error"
                v-b-modal.modal-delete-css-selectors
                @click="indexToDelete = index"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <hr />
          </div>

          <div
            v-if="hasCssSelectors"
            class="d-flex flex-row align-items-center"
          >
            <v-btn fab small color="green" dark @click="addCssSelectors">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <span class="ml-1">{{
              $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DISCOVERY.LABEL_ADD_CSS")
            }}</span>
          </div>

          <b-modal
            id="modal-delete-css-selectors"
            :title="
              $tc(
                'ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_DELETE.TITLE'
              )
            "
            hide-footer
            hide-header-close
          >
            <p class="my-4">
              {{
                $tc(
                  "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_DELETE.LABEL_DELETE"
                )
              }}
            </p>

            <div class="d-flex flex-row justify-content-center">
              <button
                class="btn btn-outline-primary"
                @click="$bvModal.hide('modal-delete-css-selectors')"
              >
                {{
                  $tc(
                    "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_DELETE.CANCEL"
                  )
                }}
              </button>

              <button
                class="btn btn-primary ml-3"
                @click="deleteCssSelectors()"
              >
                {{
                  $tc(
                    "ADVANCED_SETTINGS.DOMAIN.CONFIG_CODE_INTEGRATION.MODAL_DELETE.SUBMIT"
                  )
                }}
              </button>
            </div>
          </b-modal>

          <input
            type="hidden"
            v-bind:id="'copyToClipboardId'"
            class="form-control"
            style="width: 100px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import config from "@/common/config";
import { executionScriptGenerator } from "@/components/discovery/script.config";
import { getAttributesRead, hasAttributeAccess } from "@/common/config/acl";
import { FORM_DISCOVERY } from "@/common/config/acl/domain/discovery";
import auth from "@/store/auth.module";

const URL_DISCOVERY_ENPOINT = config.items.api_discovery;

export default {
  name: "DiscoverySettings",

  props: {
    formProps: {
      type: Object,
      required: false
    },
    userCompany: {
      type: Object,
      required: false
    },
    domainCompany: {
      type: Object,
      required: false
    },
    domainId: {
      type: String,
      required: false
    },
    hostname: {
      type: String,
      required: false
    },
    isCanCheck: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      user: auth.state.user,
      form: getAttributesRead(FORM_DISCOVERY, auth.state.user.roles),
      isLoading: false,
      indexToDelete: 0
    };
  },

  mounted() {
    if (this.domainId) {
      this.form = this.formProps;
    }
  },

  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Discovery",
        pageCategory: "Script settings"
      }
    ]);
  },

  computed: {
    ...mapGetters(["isAdmin", "isPaidCustomer"]),

    hasCssSelectors() {
      return this.form.discovery.cssSelector.length > 0;
    }
  },

  methods: {
    hasAccess(field, accessType) {
      return hasAttributeAccess(
        FORM_DISCOVERY,
        this.user.roles,
        field,
        accessType
      );
    },
    copyToClipboard() {
      let content = "";
      let companyId = "empty";

      if (this.isAdmin) {
        companyId = this.domainCompany?.id || "empty";
      } else if (this.isPaidCustomer && this.userCompany) {
        companyId = this.userCompany?.id || "empty";
      }

      const cssValues = this.form.discovery.cssSelector.map(
        element => element.css
      );
      const cssString = cssValues.join(", ");

      content = executionScriptGenerator({
        slots: cssString,
        debugMode: this.form.debugMode,
        companyId: companyId,
        endpoint: URL_DISCOVERY_ENPOINT,
        hostname: this.hostname
      });

      let copyContent = document.querySelector(`#copyToClipboardId`);
      copyContent.value = content;
      copyContent.setAttribute("type", "text");
      copyContent.focus();

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        copyContent.contentEditable = true;
        copyContent.readOnly = true;

        const range = document.createRange();
        range.selectNodeContents(copyContent);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        copyContent.setSelectionRange(0, 999999);
      } else {
        copyContent.select();
      }

      let message = "success";
      let variant = "success";
      let autoHideDelay = 3000;

      try {
        document.execCommand("Copy");
      } catch (err) {
        message = "unknown error";
        variant = "danger";
      }

      this.$bvToast.toast(`Copy to clipboard`, {
        title: `Copy embed to clipboard ${message}`,
        toaster: "b-toaster-bottom-right",
        solid: true,
        variant: variant,
        appendToast: false,
        autoHideDelay: autoHideDelay
      });

      copyContent.setAttribute("type", "hidden");
    },

    addCssSelectors() {
      this.form.discovery.cssSelector.push({
        title: `CSS Selector #${this.form.discovery.cssSelector.length + 1}`,
        titleEditable: false,
        css: ""
      });
    },

    addFirstCssSelectors() {
      this.addCssSelectors();
    },

    changeTitleEditable(index) {
      const findIndex = this.form.discovery.cssSelector.findIndex(
        element => element.titleEditable === true
      );
      if (findIndex > -1) {
        this.form.discovery.cssSelector[findIndex].titleEditable = false;
      }
      this.form.discovery.cssSelector[index].titleEditable = true;

      setTimeout(() => {
        document.getElementById(`input-title-css-${index}`).focus();
      }, 20);
    },

    changetitle(e, index) {
      this.form.discovery.cssSelector[index].title = e.target.innerText;
    },

    checkTitle(title) {
      return this.isCanCheck ? title.length > 3 : null;
    },

    checkCss(cssValue) {
      return this.isCanCheck ? cssValue.length > 0 : null;
    },

    deleteCssSelectors() {
      this.form.discovery.cssSelector.splice(this.indexToDelete, 1);
      this.indexToDelete = null;
      this.$bvModal.hide("modal-delete-css-selectors");
    },

    hasErrors() {
      if (this.hasAccess("discovery", "read")) {
        const hasErrors = this.form.discovery.cssSelector.filter(element => {
          if (
            this.hasAccess("discovery", "write") &&
            (!this.checkTitle(element.title) || !this.checkCss(element.css))
          ) {
            return element;
          }
        });

        return hasErrors.length > 0;
      }

      return false;
    },

    resetData() {
      this.form = getAttributesRead(FORM_DISCOVERY, this.user.roles);
    }
  }
};
</script>

<style scoped>
.title-code-integration {
  display: block;
  min-height: 38.39px;
  font-size: 13px;
  cursor: pointer;
}
.title-code-integration:hover {
  background: #ebecf0;
}
</style>
