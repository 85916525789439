<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-links"
  >
    <div class="card mx-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_links"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.TILE") }}
            <span class="text-danger">*</span>
          </span>
        </div>
      </div>

      <div
        id="collapse_links"
        class="collapse"
        data-parent="#accordion-form-links"
      >
        <div class="card-body">
          <div
            v-if="hasAccess('homeWebsiteUrl', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 w-25"
              >{{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.WEBSITE.LABEL") }}
              <span class="text-danger">*</span>
              <HelperTooltip
                icon="mdi-help-circle"
                :text="
                  $tc('ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.WEBSITE.TOOLTIP')
                "
              />
            </label>
            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.homeWebsiteUrl"
                :placeholder="
                  $tc(
                    'ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.LABEL_WEBSITE.TITLE'
                  )
                "
                class="form-control--grey"
                :state="checkWebsite"
                :disabled="!hasAccess('homeWebsiteUrl', 'write')"
              />

              <b-form-invalid-feedback :state="checkWebsite">
                {{ $tc("ADVANCED_SETTINGS.DOMAIN.FORM_ERROR.WEBSITE") }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            v-if="hasAccess('urlTerms', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 w-25"
              >{{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.LABEL_TERMS") }}
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.urlTerms"
                :placeholder="
                  $tc('ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.LABEL_TERMS')
                "
                class="form-control--grey"
                :state="checkTermsLink"
                :disabled="!hasAccess('urlTerms', 'write')"
              />

              <b-form-invalid-feedback :state="checkTermsLink">
                {{ $tc("ADVANCED_SETTINGS.DOMAIN.FORM_ERROR.TERMS_LINK") }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            v-if="hasAccess('urlPrivacy', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 w-25"
              >{{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.LABEL_PRIVACY") }}
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.urlPrivacy"
                :placeholder="
                  $tc('ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.LABEL_PRIVACY')
                "
                class="form-control--grey"
                :state="checkPrivacyLink"
                :disabled="!hasAccess('urlPrivacy', 'write')"
              />

              <b-form-invalid-feedback :state="checkPrivacyLink">
                {{ $tc("ADVANCED_SETTINGS.DOMAIN.FORM_ERROR.PRIVACY_LINK") }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            v-if="hasAccess('urlLegals', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 w-25"
              >{{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.LABEL_LEGALS") }}
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.urlLegals"
                :placeholder="
                  $tc('ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.LABEL_LEGALS')
                "
                class="form-control--grey"
                :state="checkLegalLink"
                :disabled="!hasAccess('urlLegals', 'write')"
              />

              <b-form-invalid-feedback :state="checkLegalLink">
                {{ $tc("ADVANCED_SETTINGS.DOMAIN.FORM_ERROR.LEGAL_LINK") }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            v-if="hasAccess('urlAbout', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 w-25"
              >{{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.LABEL_LINKS") }}
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.urlAbout"
                :placeholder="
                  $tc('ADVANCED_SETTINGS.DOMAIN.CONFIG_LINKS.LABEL_LINKS')
                "
                class="form-control--grey"
                :state="checkAboutLink"
                :disabled="!hasAccess('urlAbout', 'write')"
              />

              <b-form-invalid-feedback :state="checkAboutLink">
                {{ $tc("ADVANCED_SETTINGS.DOMAIN.FORM_ERROR.LEGAL_LINK") }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isURL } from "@/common/functions";
import { getAttributesRead, hasAttributeAccess } from "@/common/config/acl";
import auth from "@/store/auth.module";
import { FORM_LINKS } from "@/common/config/acl/domain/links";
import HelperTooltip from "@/components/HelperTooltip";

export default {
  props: {
    formProps: {
      type: Object,
      required: false
    },
    domainId: {
      type: String,
      required: false
    },
    isCanCheck: {
      type: Boolean,
      required: false
    }
  },

  components: {
    HelperTooltip
  },

  data() {
    return {
      user: auth.state.user,
      form: getAttributesRead(FORM_LINKS, auth.state.user.roles)
    };
  },

  mounted() {
    if (this.domainId) {
      this.form = this.formProps;
    }
  },

  computed: {
    checkWebsite() {
      return this.isCanCheck ? isURL(this.form.homeWebsiteUrl) : null;
    },

    checkTermsLink() {
      if (this.form.urlTerms.length > 0 && this.isCanCheck) {
        return this.isCanCheck ? isURL(this.form.urlTerms) : null;
      }

      return this.isCanCheck ? true : null;
    },

    checkPrivacyLink() {
      if (this.form.urlPrivacy.length > 0 && this.isCanCheck) {
        return this.isCanCheck ? isURL(this.form.urlPrivacy) : null;
      }

      return this.isCanCheck ? true : null;
    },

    checkLegalLink() {
      if (this.form.urlLegals && this.form.urlLegals.length > 0) {
        return this.isCanCheck ? isURL(this.form.urlLegals) : null;
      }

      return this.isCanCheck ? true : null;
    },

    checkAboutLink() {
      if (
        this.hasAccess("urlAbout", "write") &&
        this.form.urlAbout &&
        this.form.urlAbout.length > 0 &&
        this.form.isCanCheck
      ) {
        return this.isCanCheck ? isURL(this.form.urlAbout) : null;
      }

      return this.isCanCheck ? true : null;
    },

    hasErrors() {
      return (
        !this.checkWebsite ||
        !this.checkTermsLink ||
        !this.checkPrivacyLink ||
        !this.checkLegalLink ||
        !this.checkAboutLink
      );
    }
  },

  methods: {
    hasAccess(field, accessType) {
      return hasAttributeAccess(FORM_LINKS, this.user.roles, field, accessType);
    },
    resetData() {
      this.form = getAttributesRead(FORM_LINKS, this.user.roles);
    }
  }
};
</script>
