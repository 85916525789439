<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-domain"
  >
    <div class="card m-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_domain"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.TITLE") }}
            <span class="text-danger">*</span>
          </span>
        </div>
      </div>

      <div
        id="collapse_domain"
        class="collapse"
        data-parent="#accordion-form-domain"
      >
        <div class="card-body">
          <div
            v-if="hasAccess('isEnabled', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="w-25 mr-2">{{
              $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.LABEL_ENABLED")
            }}</label>

            <div class="d-flex flex-column w-75">
              <b-form-checkbox
                v-model="form.isEnabled"
                size="lg"
                name="switch-enabled"
                switch
                :disabled="!hasAccess('isEnabled', 'write')"
              />
            </div>
          </div>

          <div
            v-if="hasAccess('company', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 w-25"
              >{{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.COMPANY") }}
              <span class="text-danger">*</span></label
            >

            <div class="d-flex flex-column w-75">
              <Combobox
                :value="
                  Object.keys(companyObject).length > 0
                    ? companyObject.name
                    : ''
                "
                :placeholder="
                  $tc('ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.COMPANY')
                "
                :items="listCompanies"
                fieldName="name"
                :errorMessage="
                  $tc('ADVANCED_SETTINGS.DOMAIN.FORM_ERROR.COMPANY')
                "
                :itemSelected="companyObject"
                :isCanCheck="isCanCheck"
                fieldToCheck="name"
                isSearchBack
                @loadItems="eventLoadCompanies"
                @selectElement="selectCompany"
                ref="company"
                :disabled="!hasAccess('company', 'write')"
              />
            </div>
          </div>

          <div
            v-if="hasAccess('name', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="w-25 mr-2"
              >{{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.LABEL_NAME") }}
              <span class="text-danger">*</span></label
            >

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.name"
                placeholder="Name"
                class="form-control--grey"
                :state="checkName"
                :disabled="!hasAccess('name', 'write')"
              />

              <b-form-invalid-feedback :state="checkName">
                {{ $tc("ADVANCED_SETTINGS.DOMAIN.FORM_ERROR.NAME") }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            v-if="hasAccess('host', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 w-25"
              >{{
                $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.LABEL_DOMAINS")
              }}
              <span class="text-danger">*</span></label
            >
            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.host"
                placeholder="Domais name"
                class="form-control--grey"
                :state="checkHost"
                :disabled="!hasAccess('host', 'write')"
                @input="onHostInputChange"
              />

              <b-form-invalid-feedback :state="checkHost">
                {{ $tc("ADVANCED_SETTINGS.DOMAIN.FORM_ERROR.DOMAIN_NAME") }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            v-if="hasAccess('template', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 w-25"
              >{{
                $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.LABEL_TEMPLATE")
              }}
              <span class="text-danger">*</span></label
            >

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.template"
                placeholder="Template name"
                class="form-control--grey"
                :state="checkTemplate"
                :disabled="!hasAccess('template', 'write')"
              />

              <b-form-invalid-feedback :state="checkTemplate">
                {{ $tc("ADVANCED_SETTINGS.DOMAIN.FORM_ERROR.TEMPLATE") }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <b-card no-body>
            <b-tabs v-model="tabIndex" @changed="onTabChanged">
              <b-tab
                card
                v-for="(obj, key) in form.multilingualContent"
                :key="'dyn-tab-' + key"
                :title="key"
              >
                <div
                  v-if="hasAccess('multilingualContent', 'read')"
                  class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center"
                >
                  <label
                    >{{
                      $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.LABEL_TITLE")
                    }}
                    <span class="text-danger">*</span></label
                  >

                  <div class="d-flex flex-column w-75">
                    <ckeditor
                      v-model="form.multilingualContent[key].title"
                      placeholder="Title on page"
                      :editor="editor"
                      :config="editorConfigTitle"
                      :disabled="!hasAccess('multilingualContent', 'write')"
                    ></ckeditor>
                  </div>
                </div>
                <br />
                <div
                  v-if="hasAccess('multilingualContent', 'read')"
                  class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center"
                >
                  <label
                    >{{
                      $tc(
                        "ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.LABEL_DESCRIPTION"
                      )
                    }}
                    <span class="text-danger">*</span></label
                  >

                  <div class="d-flex flex-column w-75">
                    <ckeditor
                      v-model="form.multilingualContent[key].tagline"
                      :editor="editor"
                      :config="editorConfigDescription"
                      :disabled="!hasAccess('multilingualContent', 'write')"
                    ></ckeditor>

                    <b-form-invalid-feedback :state="checkMultilingualContent">
                      {{
                        $tc("ADVANCED_SETTINGS.DOMAIN.FORM_ERROR.MULTILINGUAL")
                      }}
                    </b-form-invalid-feedback>
                  </div>
                </div>

                <b-button
                  v-if="tabIndex !== 0"
                  size="sm"
                  variant="danger"
                  class="float-right mt-3"
                  @click="removeTab()"
                >
                  Delete
                </b-button>
              </b-tab>

              <!-- New Tab Button -->
              <template #tabs-end>
                <b-nav-item-dropdown role="presentation" href="#">
                  <template #button-content>
                    <v-icon>mdi-plus</v-icon>
                    Add a language
                  </template>

                  <b-dropdown-item
                    v-for="(language, key) in dataLanguages"
                    :key="key"
                    :disabled="language.disabled"
                    href="#"
                    @click.prevent="newTab(language.codeIso1)"
                  >
                    {{ language.codeIso2 }} ({{ language.nameEn }})
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </template>

              <!-- Render this if no tabs -->
              <template #empty>
                <div class="text-center text-muted">
                  There are no open tabs<br />
                  Open a new tab using the <b>+</b> button above.
                </div>
              </template>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-tab"
      :title="
        $tc('ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.MODAL.LANGUAGE.TITLE')
      "
      hide-header-close
    >
      <p class="my-4">
        {{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_DOMAIN.MODAL.LANGUAGE.BODY") }}
      </p>

      <template #modal-footer="{ close }">
        <button class="btn border rounded mr-3" @click="close">
          {{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE.CANCEL") }}
        </button>

        <button class="btn btn-danger mr-3" @click="confirmModal">
          <template>
            {{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE.SUBMIT") }}
          </template>
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCompaniesV2 } from "@/api/companies/getters";
import Combobox from "@/components/settings/Combobox";
import { getAttributesRead, hasAttributeAccess } from "@/common/config/acl";
import { FORM_DOMAINS } from "@/common/config/acl/domain/domains";
import auth from "@/store/auth.module";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getLanguages } from "@/api/languages/getters";

export default {
  props: {
    formProps: {
      type: Object,
      required: false
    },
    domainId: {
      type: String,
      required: false
    },
    isCanCheck: {
      type: Boolean,
      required: false
    }
  },

  components: {
    Combobox
  },

  data() {
    return {
      user: auth.state.user,
      form: getAttributesRead(FORM_DOMAINS, auth.state.user.roles),
      languages: {},
      companyObject: {},
      listCompanies: [],
      editor: ClassicEditor,
      editorConfigTitle: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "blockQuote",
          "|",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent"
        ],
        placeholder: "Title on page",
        charset: "utf-8"
      },
      editorConfigDescription: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "blockQuote",
          "|",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent"
        ],
        charset: "utf-8"
      },
      tabIndex: 0
    };
  },

  mounted() {
    this.loadLanguages();

    this.loadCompanies({ page: 1, search: "" });

    if (this.domainId) {
      this.form = this.formProps;
      this.companyObject = this.formProps.companyObject;
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: "isAdmin"
    }),

    checkName() {
      return this.isCanCheck ? this.form.name.length >= 3 : null;
    },

    checkHost() {
      return this.isCanCheck ? this.form.host.length >= 3 : null;
    },

    checkTemplate() {
      if (!this.form.hasOwnProperty("template")) {
        return true;
      }
      return this.isCanCheck ? this.form.template.length >= 3 : null;
    },

    checkMultilingualContent() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, obj] of Object.entries(this.form.multilingualContent)) {
        if (
          this.checkText(obj.title) === false ||
          this.checkText(obj.tagline) === false
        ) {
          return false;
        }
      }
      return true;
    },

    hasErrors() {
      return (
        (this.$refs.company !== undefined &&
          !this.$refs.company.checkCombobox) ||
        !this.checkName ||
        !this.checkHost ||
        !this.checkTemplate ||
        !this.checkMultilingualContent
      );
    },

    dataLanguages() {
      let data = [];
      let compare = [];

      for (const key of Object.keys(this.form.multilingualContent)) {
        compare.push(key);
      }

      if (this.languages.length) {
        this.languages.forEach(element => {
          element.disabled = compare.includes(element.codeIso1);
          data.push(element);
        });
      }

      return data;
    }
  },

  methods: {
    checkText(text) {
      return this.isCanCheck ? text.length >= 10 : null;
    },

    hasAccess(field, accessType) {
      return hasAttributeAccess(
        FORM_DOMAINS,
        this.user.roles,
        field,
        accessType
      );
    },

    async loadLanguages() {
      try {
        this.languages = await getLanguages(this.axios);
        this.languages.shift(); //remove fr because already default
      } catch (error) {
        console.error("---ERROR-LOAD-LANGUAGES");
        console.error(error);
      }
    },

    eventLoadCompanies(filters) {
      this.loadCompanies(filters);
    },

    async loadCompanies({ page, search }) {
      try {
        const response = await getCompaniesV2(this.axios, { page, search });
        this.listCompanies = response.data;
      } catch (error) {
        console.error("---ERROR-COMPANIES----");
        console.error(error);
      }
    },

    selectCompany(item) {
      this.form.company = item.id;
      this.companyObject = item;
      this.$emit("company-select-change", item);
    },

    resetData() {
      this.form = getAttributesRead(FORM_DOMAINS, this.user.roles);
      this.companyObject = {};
      this.listCompanies = [];
    },

    onHostInputChange() {
      this.$emit("host-input-change", this.form.host);
    },

    removeTab() {
      this.$bvModal.show("modal-tab");
    },

    confirmModal() {
      this.form.multilingualContent = Object.assign(
        {},
        delete this.form.multilingualContent[
          Object.keys(this.form.multilingualContent)[this.tabIndex]
        ],
        this.form.multilingualContent
      );

      this.$bvModal.hide("modal-tab");
    },

    newTab(lang) {
      this.form.multilingualContent = Object.assign(
        {},
        this.form.multilingualContent,
        {
          [lang]: { siteTitle: "", tagline: "" }
        }
      );
    },

    onTabChanged() {
      this.tabIndex = Object.keys(this.form.multilingualContent).length - 1;
    }
  }
};
</script>
