import { ROLES } from "@/common/config/acl/roles";
import { stringifyJson } from "@/assets/js/helper";
import { defaultRobotsGoogleVerification } from "@/components/settings/settings.config";

export const FORM_GOOGLE_SEARCH = {
  robotsGoogleVerificationOwner: {
    defaultValue: stringifyJson(defaultRobotsGoogleVerification),
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  }
};
