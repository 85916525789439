import { get as getV2 } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const getLanguages = axios =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.LANGUAGES.path
    },
    {},
    axios
  );
