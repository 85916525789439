import { ROLES } from "@/common/config/acl/roles";

export const FORM_CODE_INTEGRATION = {
  customTemplate: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  activatedCustomContent: {
    defaultValue: false,
    read: [ROLES.BBB_ADMIN, ROLES.PUBLISHER_ADMIN, ROLES.ADVERTISER_ADMIN],
    write: [ROLES.BBB_ADMIN, ROLES.PUBLISHER_ADMIN, ROLES.ADVERTISER_ADMIN]
  }
};
