import { ROLES } from "@/common/config/acl/roles";

export const FORM_ONE_SIGNAL = {
  oneSignal: {
    defaultValue: {
      id: "",
      auth: "",
      db: "",
      pushMigration: ""
    },
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN]
  }
};
