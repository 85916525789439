import { ROLES } from "@/common/config/acl/roles";

const ROLES_ADMIN = [
  ROLES.BBB_ADMIN,
  ROLES.BBB_MANAGER_PUBLISHER,
  ROLES.BBB_MANAGER_ADVERTISER
];

export const FORM_DOMAINS = {
  isEnabled: {
    defaultValue: true,
    read: [...ROLES_ADMIN],
    write: [...ROLES_ADMIN]
  },
  name: {
    defaultValue: "",
    read: [...ROLES_ADMIN, ROLES.PUBLISHER_ADMIN, ROLES.ADVERTISER_ADMIN],
    write: [...ROLES_ADMIN]
  },
  host: {
    defaultValue: "",
    read: [...ROLES_ADMIN, ROLES.PUBLISHER_ADMIN, ROLES.ADVERTISER_ADMIN],
    write: [...ROLES_ADMIN]
  },
  siteTitle: {
    defaultValue: "",
    read: [...ROLES_ADMIN, ROLES.PUBLISHER_ADMIN, ROLES.ADVERTISER_ADMIN],
    write: [...ROLES_ADMIN, ROLES.PUBLISHER_ADMIN, ROLES.ADVERTISER_ADMIN]
  },
  description: {
    defaultValue: "",
    read: [...ROLES_ADMIN, ROLES.PUBLISHER_ADMIN, ROLES.ADVERTISER_ADMIN],
    write: [...ROLES_ADMIN, ROLES.PUBLISHER_ADMIN, ROLES.ADVERTISER_ADMIN]
  },
  template: {
    defaultValue: "",
    read: [...ROLES_ADMIN],
    write: [...ROLES_ADMIN]
  },
  company: {
    defaultValue: undefined,
    read: [...ROLES_ADMIN],
    write: [...ROLES_ADMIN]
  },
  multilingualContent: {
    defaultValue: { default: { title: " ", tagline: " " } },
    read: [...ROLES_ADMIN, ROLES.PUBLISHER_ADMIN, ROLES.ADVERTISER_ADMIN],
    write: [...ROLES_ADMIN, ROLES.PUBLISHER_ADMIN, ROLES.ADVERTISER_ADMIN]
  }
};
